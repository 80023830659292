import {
  ComponentStateInterface,
  HealthKPIAggregatedResponseSchema,
  HealthKPIAggregationScope,
  HealthKPIAggregationType,
  HealthKPIEnum,
  HealthKPILatestParameters,
  HealthKPILatestResponseSchema,
  KpiStateDetailsInterface,
  SafetyRecommendationComponentDetailsInterface,
  SafetyRecommendationDataInterface,
  SystemIngestionStatistics,
  ValidKPIConfiguration,
  WarrantyKPIConfiguration,
  WarrantyKPIDataInterface,
  WarrantyKPIEnum,
  WarrantyKPIParamsInterface,
} from '@twaice-fe/shared/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { energyAnalyticsActions } from '../actions';

interface ErrorPayload {
  error: string;
}
export const ENERGY_ANALYTICS_FEATURE_KEY = 'energy-analytics';

export interface RemoteState {
  isLoading: boolean;
  error: string | null;
}

// region Safety Recommendation state
export interface SafetyRecommendationDataState extends RemoteState {
  systemState: string;
  numberOfStableStrings: number;
  requiredActions: EntityState<ComponentStateInterface>;
  recommendedActions: EntityState<ComponentStateInterface>;
}

export interface SafetyRecommendationComponentDetailsState extends RemoteState {
  componentBk: string;
  kpiStates: KpiStateDetailsInterface[];
}

export const safetyActionAdapter: EntityAdapter<ComponentStateInterface> = createEntityAdapter<ComponentStateInterface>({
  selectId: (safetyActionData) => safetyActionData.componentBk,
});

export const initialSafetyRecommendationDataState: SafetyRecommendationDataState = {
  systemState: '',
  numberOfStableStrings: 0,
  requiredActions: safetyActionAdapter.getInitialState(),
  recommendedActions: safetyActionAdapter.getInitialState(),
  error: null,
  isLoading: false,
};

export const initialSafetyRecommendationComponentDetailsState: SafetyRecommendationComponentDetailsState = {
  componentBk: '',
  kpiStates: [],
  error: null,
  isLoading: false,
};
// endregion

// region System Ingestion Statistics state
export interface SystemIngestionStatisticsState extends RemoteState {
  lastSeen: Date | null;
}

const initialSystemIngestionStatisticsState: SystemIngestionStatisticsState = {
  isLoading: false,
  error: null,
  lastSeen: null,
};
// endregion

// region Warranty tracker state
export interface WarrantyKPIDataState extends RemoteState {
  data: WarrantyKPIDataInterface;
}

const initialWarrantyKPIDataState: WarrantyKPIDataState = {
  data: {
    kpi: WarrantyKPIEnum.EQUIVALENT_FULL_CYCLES,
    customerBk: '',
    systemBk: '',
    warrantySensorValues: [],
    warrantyReferenceValues: [],
  },
  isLoading: false,
  error: null,
};

export interface WarrantyKPIConfigurationState extends RemoteState {
  data: WarrantyKPIConfiguration;
}

const initialWarrantyKPIConfigurationState: WarrantyKPIConfigurationState = {
  data: {
    customerBk: '',
    systemBk: '',
    kpis: [],
  },
  isLoading: false,
  error: null,
};

export type ValidKPIConfigurationState = EntityState<ValidKPIConfiguration> & RemoteState;

export const validKPIConfigurationAdapter: EntityAdapter<ValidKPIConfiguration> = createEntityAdapter<ValidKPIConfiguration>({
  selectId: (validKPIConfiguration) => validKPIConfiguration.kpiType,
});

const initialValidKPIConfigurationState: ValidKPIConfigurationState = validKPIConfigurationAdapter.getInitialState({
  isLoading: false,
  error: null,
});
// endregion

// region Health KPI Data State
export interface LatestHealthKPIDataState extends RemoteState {
  data: HealthKPILatestResponseSchema;
}

const initialLatestHealthKPIDataState: LatestHealthKPIDataState = {
  data: {
    customerBk: '',
    systemBk: '',
    levelBk: '',
    aggregationScope: HealthKPIAggregationScope.PER_ENTITY,
    aggregationType: HealthKPIAggregationType.NONE,
    kpi: HealthKPIEnum.EFC,
    data: [],
  },
  isLoading: false,
  error: null,
};

export interface AggregatedHealthKPIDataState extends RemoteState {
  data: HealthKPIAggregatedResponseSchema;
}

const initialAggregatedHealthKPIDataState: AggregatedHealthKPIDataState = {
  data: {
    customerBk: '',
    systemBk: '',
    levelBk: '',
    aggregationScope: HealthKPIAggregationScope.PER_ENTITY,
    aggregationType: HealthKPIAggregationType.NONE,
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    kpi: HealthKPIEnum.EFC,
    data: [],
  },
  isLoading: false,
  error: null,
};

export interface HealthKPIState {
  latest: LatestHealthKPIDataState;
  aggregated: AggregatedHealthKPIDataState;
}

// endregion

// region State
export interface State {
  customerBk: string;
  systemBk: string;
  healthKPIData: {
    [HealthKPIEnum.DCR]: HealthKPIState;
    [HealthKPIEnum.EFC]: HealthKPIState;
    [HealthKPIEnum.SOH]: HealthKPIState;
    [HealthKPIEnum.TEMPERATURE_SPREAD]: HealthKPIState;
    [HealthKPIEnum.VOLTAGE_SPREAD]: HealthKPIState;
  };
  safetyRecommendationData: SafetyRecommendationDataState;
  systemIngestionStatistics: SystemIngestionStatisticsState;
  safetyRecommendationComponentDetails: SafetyRecommendationComponentDetailsState;
  warrantyKPIData: {
    [WarrantyKPIEnum.EQUIVALENT_FULL_CYCLES]: WarrantyKPIDataState;
    [WarrantyKPIEnum.STATE_OF_HEALTH]: WarrantyKPIDataState;
    [WarrantyKPIEnum.AVAILABILITY]: WarrantyKPIDataState;
    [WarrantyKPIEnum.ROUND_TRIP_EFFICIENCY]: WarrantyKPIDataState;
  };
  warrantyKPIConfiguration: WarrantyKPIConfigurationState;
  warrantyValidKPIConfiguration: ValidKPIConfigurationState;
}

export const initialState: State = {
  customerBk: '',
  systemBk: '',
  healthKPIData: {
    [HealthKPIEnum.DCR]: {
      latest: { ...initialLatestHealthKPIDataState, data: { ...initialLatestHealthKPIDataState.data, kpi: HealthKPIEnum.DCR } },
      aggregated: {
        ...initialAggregatedHealthKPIDataState,
        data: { ...initialAggregatedHealthKPIDataState.data, kpi: HealthKPIEnum.DCR },
      },
    },
    [HealthKPIEnum.EFC]: {
      latest: { ...initialLatestHealthKPIDataState, data: { ...initialLatestHealthKPIDataState.data, kpi: HealthKPIEnum.EFC } },
      aggregated: {
        ...initialAggregatedHealthKPIDataState,
        data: { ...initialAggregatedHealthKPIDataState.data, kpi: HealthKPIEnum.EFC },
      },
    },
    [HealthKPIEnum.SOH]: {
      latest: { ...initialLatestHealthKPIDataState, data: { ...initialLatestHealthKPIDataState.data, kpi: HealthKPIEnum.SOH } },
      aggregated: {
        ...initialAggregatedHealthKPIDataState,
        data: { ...initialAggregatedHealthKPIDataState.data, kpi: HealthKPIEnum.SOH },
      },
    },
    [HealthKPIEnum.TEMPERATURE_SPREAD]: {
      latest: {
        ...initialLatestHealthKPIDataState,
        data: { ...initialLatestHealthKPIDataState.data, kpi: HealthKPIEnum.TEMPERATURE_SPREAD },
      },
      aggregated: {
        ...initialAggregatedHealthKPIDataState,
        data: { ...initialAggregatedHealthKPIDataState.data, kpi: HealthKPIEnum.TEMPERATURE_SPREAD },
      },
    },
    [HealthKPIEnum.VOLTAGE_SPREAD]: {
      latest: {
        ...initialLatestHealthKPIDataState,
        data: { ...initialLatestHealthKPIDataState.data, kpi: HealthKPIEnum.VOLTAGE_SPREAD },
      },
      aggregated: {
        ...initialAggregatedHealthKPIDataState,
        data: { ...initialAggregatedHealthKPIDataState.data, kpi: HealthKPIEnum.VOLTAGE_SPREAD },
      },
    },
  },
  safetyRecommendationData: { ...initialSafetyRecommendationDataState },
  systemIngestionStatistics: { ...initialSystemIngestionStatisticsState },
  safetyRecommendationComponentDetails: { ...initialSafetyRecommendationComponentDetailsState },
  warrantyKPIData: {
    [WarrantyKPIEnum.EQUIVALENT_FULL_CYCLES]: {
      ...initialWarrantyKPIDataState,
      data: { ...initialWarrantyKPIDataState.data, kpi: WarrantyKPIEnum.EQUIVALENT_FULL_CYCLES },
    },
    [WarrantyKPIEnum.STATE_OF_HEALTH]: {
      ...initialWarrantyKPIDataState,
      data: { ...initialWarrantyKPIDataState.data, kpi: WarrantyKPIEnum.STATE_OF_HEALTH },
    },
    [WarrantyKPIEnum.AVAILABILITY]: {
      ...initialWarrantyKPIDataState,
      data: { ...initialWarrantyKPIDataState.data, kpi: WarrantyKPIEnum.AVAILABILITY },
    },
    [WarrantyKPIEnum.ROUND_TRIP_EFFICIENCY]: {
      ...initialWarrantyKPIDataState,
      data: { ...initialWarrantyKPIDataState.data, kpi: WarrantyKPIEnum.ROUND_TRIP_EFFICIENCY },
    },
  },
  warrantyKPIConfiguration: { ...initialWarrantyKPIConfigurationState },
  warrantyValidKPIConfiguration: initialValidKPIConfigurationState,
};
// endregion

type ActionReducer<T = unknown> = (state: State, payload: T) => State;

//#region Safety Recommendation reducers
const reduceFetchSafetyRecommendationData: ActionReducer = (state) => ({
  ...state,
  safetyRecommendationData: { ...initialSafetyRecommendationDataState, isLoading: true },
});

const reduceLoadSafetyRecommendationDataSuccess: ActionReducer<{
  safetyRecommendationData: SafetyRecommendationDataInterface;
}> = (state, { safetyRecommendationData }) => ({
  ...state,
  safetyRecommendationData: {
    ...state.safetyRecommendationData,
    ...safetyRecommendationData,
    requiredActions: safetyActionAdapter.upsertMany(
      safetyRecommendationData.requiredActions,
      state.safetyRecommendationData.requiredActions
    ),
    recommendedActions: safetyActionAdapter.upsertMany(
      safetyRecommendationData.recommendedActions,
      state.safetyRecommendationData.recommendedActions
    ),
    isLoading: false,
    error: null,
  },
});

const reduceLoadSafetyRecommendationDataFailure: ActionReducer<ErrorPayload> = (state, { error }) => ({
  ...state,
  safetyRecommendationData: {
    ...state.safetyRecommendationData,
    isLoading: false,
    error,
  },
});

const reduceFetchSafetyRecommendationComponentDetails: ActionReducer<{ componentBk: string }> = (state, { componentBk }) => ({
  ...state,
  safetyRecommendationComponentDetails: { ...initialSafetyRecommendationComponentDetailsState, componentBk, isLoading: true },
});

const reduceLoadSafetyRecommendationComponentDetailsSuccess: ActionReducer<{
  safetyRecommendationComponentDetails: SafetyRecommendationComponentDetailsInterface;
}> = (state, { safetyRecommendationComponentDetails }) => ({
  ...state,
  safetyRecommendationComponentDetails: {
    ...state.safetyRecommendationComponentDetails,
    ...safetyRecommendationComponentDetails,
    isLoading: false,
    error: null,
  },
});

const reduceLoadSafetyRecommendationDetailsFailure: ActionReducer<ErrorPayload> = (state, { error }) => ({
  ...state,
  safetyRecommendationComponentDetails: {
    ...state.safetyRecommendationComponentDetails,
    isLoading: false,
    error,
  },
});
// endregion

// region  System Ingestion Statistics reducers
const reduceFetchSystemIngestionStatisticsData: ActionReducer = (state) => ({
  ...state,
  systemIngestionStatistics: { ...initialSystemIngestionStatisticsState, isLoading: true },
});

const reduceLoadSystemIngestionStatisticsDataSuccess: ActionReducer<{ systemIngestionStatistics: SystemIngestionStatistics }> = (
  state,
  { systemIngestionStatistics }
) => ({
  ...state,
  systemIngestionStatistics: { ...state.systemIngestionStatistics, ...systemIngestionStatistics, isLoading: false, error: null },
});

const reduceLoadSystemIngestionStatisticsDataFailure: ActionReducer<ErrorPayload> = (state, { error }) => ({
  ...state,
  systemIngestionStatistics: {
    ...state.systemIngestionStatistics,
    isLoading: false,
    error,
  },
});
// endregion

// region  Warranty tracker reducers
const reduceFetchWarrantyKPIData: ActionReducer<WarrantyKPIParamsInterface> = (state, params) => ({
  ...state,
  warrantyKPIData: {
    ...state.warrantyKPIData,
    [params.kpi]: { ...state.warrantyKPIData[params.kpi], isLoading: true, error: null },
  },
});

const reduceLoadWarrantyKPIDataDataSuccess: ActionReducer<{ warrantyKPIData: WarrantyKPIDataInterface }> = (
  state,
  { warrantyKPIData }
) => ({
  ...state,
  warrantyKPIData: {
    ...state.warrantyKPIData,
    [warrantyKPIData.kpi]: {
      data: {
        ...state.warrantyKPIData[warrantyKPIData.kpi].data,
        ...warrantyKPIData,
      },
      isLoading: false,
      error: null,
    },
  },
});

const reduceLoadWarrantyKPIDataDataFailure: ActionReducer<{ kpi: WarrantyKPIEnum; error: string }> = (state, { kpi, error }) => ({
  ...state,
  warrantyKPIData: {
    ...state.warrantyKPIData,
    [kpi]: {
      ...state.warrantyKPIData[kpi],
      isLoading: false,
      error,
    },
  },
});

const reduceFetchWarrantyKPIConfiguration: ActionReducer = (state) => ({
  ...state,
  warrantyKPIConfiguration: { ...initialWarrantyKPIConfigurationState, isLoading: true },
});

const reduceLoadWarrantyKPIConfigurationSuccess: ActionReducer<{ warrantyKPIConfiguration: WarrantyKPIConfiguration }> = (
  state,
  { warrantyKPIConfiguration }
) => ({
  ...state,
  warrantyKPIConfiguration: {
    data: {
      ...state.warrantyKPIConfiguration.data,
      ...warrantyKPIConfiguration,
    },
    isLoading: false,
    error: null,
  },
});

const reduceLoadWarrantyKPIConfigurationFailure: ActionReducer<ErrorPayload> = (state, { error }) => ({
  ...state,
  warrantyKPIConfiguration: {
    ...state.warrantyKPIConfiguration,
    isLoading: false,
    error,
  },
});

const reduceFetchWarrantyValidKPIConfiguration: ActionReducer = (state) => ({
  ...state,
  warrantyValidKPIConfiguration: validKPIConfigurationAdapter.setAll([], {
    ...state.warrantyValidKPIConfiguration,
    isLoading: true,
    error: null,
  }),
});

const reduceLoadWarrantyValidKPIConfigurationSuccess: ActionReducer<{
  warrantyValidKPIConfiguration: ValidKPIConfiguration[];
}> = (state, { warrantyValidKPIConfiguration }) => ({
  ...state,
  warrantyValidKPIConfiguration: validKPIConfigurationAdapter.setAll(warrantyValidKPIConfiguration, {
    ...state.warrantyValidKPIConfiguration,
    isLoading: false,
    error: null,
  }),
});

const reduceLoadWarrantyValidKPIConfigurationFailure: ActionReducer<ErrorPayload> = (state, { error }) => ({
  ...state,
  warrantyValidKPIConfiguration: validKPIConfigurationAdapter.setAll([], {
    ...state.warrantyValidKPIConfiguration,
    isLoading: false,
    error,
  }),
});
// endregion

// region Health KPI reducers
const reduceFetchLatestHealthKPIData: ActionReducer<{ kpi: HealthKPIEnum; params: HealthKPILatestParameters }> = (
  state,
  { kpi }
) => ({
  ...state,
  healthKPIData: {
    ...state.healthKPIData,
    [kpi]: {
      latest: { ...initialLatestHealthKPIDataState, isLoading: true, error: null },
      aggregated: state.healthKPIData[kpi].aggregated,
    },
  },
});

const reduceLoadLatestHealthKPIDataSuccess: ActionReducer<HealthKPILatestResponseSchema> = (state, healthKPIData) => ({
  ...state,
  healthKPIData: {
    ...state.healthKPIData,
    [healthKPIData.kpi]: {
      latest: {
        data: {
          ...state.healthKPIData[healthKPIData.kpi].latest.data,
          ...healthKPIData,
        },
        isLoading: false,
        error: null,
      },
      aggregated: state.healthKPIData[healthKPIData.kpi].aggregated,
    },
  },
});

const reduceLoadLatestHealthKPIDataFailure: ActionReducer<{ kpi: HealthKPIEnum; error: string }> = (state, { kpi, error }) => ({
  ...state,
  healthKPIData: {
    ...state.healthKPIData,
    [kpi]: {
      latest: { ...state.healthKPIData[kpi].latest, isLoading: false, error },
      aggregated: state.healthKPIData[kpi].aggregated,
    },
  },
});

const reduceFetchAggregatedHealthKPIData: ActionReducer<{ kpi: HealthKPIEnum; params: HealthKPILatestParameters }> = (
  state,
  { kpi }
) => ({
  ...state,
  healthKPIData: {
    ...state.healthKPIData,
    [kpi]: {
      latest: state.healthKPIData[kpi].latest,
      aggregated: { ...initialAggregatedHealthKPIDataState, isLoading: true, error: null },
    },
  },
});

const reduceLoadAggregatedHealthKPIDataSuccess: ActionReducer<HealthKPIAggregatedResponseSchema> = (state, healthKPIData) => ({
  ...state,
  healthKPIData: {
    ...state.healthKPIData,
    [healthKPIData.kpi]: {
      latest: state.healthKPIData[healthKPIData.kpi].latest,
      aggregated: {
        data: {
          ...state.healthKPIData[healthKPIData.kpi].aggregated.data,
          ...healthKPIData,
        },
        isLoading: false,
        error: null,
      },
    },
  },
});

const reduceLoadAggregatedHealthKPIDataFailure: ActionReducer<{ kpi: HealthKPIEnum; error: string }> = (
  state,
  { kpi, error }
) => ({
  ...state,
  healthKPIData: {
    ...state.healthKPIData,
    [kpi]: {
      latest: state.healthKPIData[kpi].latest,
      aggregated: { ...state.healthKPIData[kpi].aggregated, isLoading: false, error },
    },
  },
});
// endregion

export const energyAnalyticsReducer = createReducer(
  initialState,
  on(energyAnalyticsActions.fetchSafetyRecommendationData, reduceFetchSafetyRecommendationData),
  on(energyAnalyticsActions.loadSafetyRecommendationDataSuccess, reduceLoadSafetyRecommendationDataSuccess),
  on(energyAnalyticsActions.loadSafetyRecommendationDataFailure, reduceLoadSafetyRecommendationDataFailure),
  on(energyAnalyticsActions.fetchSafetyRecommendationComponentDetails, reduceFetchSafetyRecommendationComponentDetails),
  on(
    energyAnalyticsActions.loadSafetyRecommendationComponentDetailsSuccess,
    reduceLoadSafetyRecommendationComponentDetailsSuccess
  ),
  on(energyAnalyticsActions.loadSafetyRecommendationComponentDetailsFailure, reduceLoadSafetyRecommendationDetailsFailure),
  on(energyAnalyticsActions.fetchSystemIngestionStatisticsData, reduceFetchSystemIngestionStatisticsData),
  on(energyAnalyticsActions.loadSystemIngestionStatisticsSuccess, reduceLoadSystemIngestionStatisticsDataSuccess),
  on(energyAnalyticsActions.loadSystemIngestionStatisticsFailure, reduceLoadSystemIngestionStatisticsDataFailure),
  on(energyAnalyticsActions.fetchWarrantyKPIData, reduceFetchWarrantyKPIData),
  on(energyAnalyticsActions.loadWarrantyKPIDataSuccess, reduceLoadWarrantyKPIDataDataSuccess),
  on(energyAnalyticsActions.loadWarrantyKPIDataFailure, reduceLoadWarrantyKPIDataDataFailure),
  on(energyAnalyticsActions.fetchWarrantyKPIConfiguration, reduceFetchWarrantyKPIConfiguration),
  on(energyAnalyticsActions.loadWarrantyKPIConfigurationSuccess, reduceLoadWarrantyKPIConfigurationSuccess),
  on(energyAnalyticsActions.loadWarrantyKPIConfigurationFailure, reduceLoadWarrantyKPIConfigurationFailure),
  on(energyAnalyticsActions.fetchWarrantyValidKPIConfiguration, reduceFetchWarrantyValidKPIConfiguration),
  on(energyAnalyticsActions.loadWarrantyValidKPIConfigurationSuccess, reduceLoadWarrantyValidKPIConfigurationSuccess),
  on(energyAnalyticsActions.loadWarrantyValidKPIConfigurationFailure, reduceLoadWarrantyValidKPIConfigurationFailure),
  on(energyAnalyticsActions.fetchLatestHealthKPIData, reduceFetchLatestHealthKPIData),
  on(energyAnalyticsActions.loadLatestHealthKPIDataSuccess, reduceLoadLatestHealthKPIDataSuccess),
  on(energyAnalyticsActions.loadLatestHealthKPIDataFailure, reduceLoadLatestHealthKPIDataFailure),
  on(energyAnalyticsActions.fetchAggregatedHealthKPIData, reduceFetchAggregatedHealthKPIData),
  on(energyAnalyticsActions.loadAggregatedHealthKPIDataSuccess, reduceLoadAggregatedHealthKPIDataSuccess),
  on(energyAnalyticsActions.loadAggregatedHealthKPIDataFailure, reduceLoadAggregatedHealthKPIDataFailure)
);
