import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeSensorTypes',
  pure: false,
  standalone: true,
})
export class SensorTypesPipe implements PipeTransform {
  transform(value: string | undefined): string | undefined {
    if (!value) return '';

    // Replace underscores with spaces and capitalize each word
    return value
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
}
