import { createAction, props } from '@ngrx/store';
import {
  HealthKPIEnum,
  HealthKPIAggregatedParameters,
  HealthKPILatestParameters,
  SafetyRecommendationComponentDetailsInterface,
  SafetyRecommendationComponentDetailsParamsInterface,
  SafetyRecommendationDataInterface,
  SystemIngestionStatistics,
  WarrantyKPIDataInterface,
  WarrantyKPIEnum,
  WarrantyKPIParamsInterface,
  HealthKPILatestResponseSchema,
  HealthKPIAggregatedResponseSchema,
  WarrantyKPIConfiguration,
  WarrantyKPIConfigurationParams,
  ValidKPIConfiguration,
} from '@twaice-fe/shared/models';

// region Safety Recommendation actions
export const fetchSafetyRecommendationData = createAction('[Energy Analytics] Fetch safety recommendation data');

export const loadSafetyRecommendationDataSuccess = createAction(
  '[Energy Analytics/API] Load safety recommendation data Success',
  props<{ safetyRecommendationData: SafetyRecommendationDataInterface }>()
);

export const loadSafetyRecommendationDataFailure = createAction(
  '[Energy Analytics/API] Load safety recommendation data Failure',
  props<{ error: string }>()
);

export const cancelSafetyRecommendationDataRequest = createAction(
  '[Energy Analytics/API] Cancel safety recommendation data http request'
);

export const fetchSafetyRecommendationComponentDetails = createAction(
  '[Energy Analytics] Fetch safety recommendation component details',
  props<SafetyRecommendationComponentDetailsParamsInterface>()
);

export const loadSafetyRecommendationComponentDetailsSuccess = createAction(
  '[Energy Analytics/API] Load safety recommendation component details Success',
  props<{ safetyRecommendationComponentDetails: SafetyRecommendationComponentDetailsInterface }>()
);

export const loadSafetyRecommendationComponentDetailsFailure = createAction(
  '[Energy Analytics/API] Load safety recommendation component details Failure',
  props<{ error: string }>()
);

export const cancelSafetyRecommendationComponentDetailsRequest = createAction(
  '[Energy Analytics/API] Cancel safety recommendation component details http request'
);
// endregion

// region  System Ingestion Statistics Actions
export const fetchSystemIngestionStatisticsData = createAction('[Energy Analytics] Fetch system ingestion statistics data');

export const loadSystemIngestionStatisticsSuccess = createAction(
  '[Energy Analytics/API] Load system ingestion statistics data Success',
  props<{ systemIngestionStatistics: SystemIngestionStatistics }>()
);

export const loadSystemIngestionStatisticsFailure = createAction(
  '[Energy Analytics/API] Load system ingestion statistics data Failure',
  props<{ error: string }>()
);

export const cancelSystemIngestionStatisticsRequest = createAction(
  '[Energy Analytics/API] Cancel system ingestion statistics data http request'
);
// endregion

// region  Warranty tracker Actions
export const fetchWarrantyKPIData = createAction(
  '[Energy Analytics] Fetch warranty tracker kpi data',
  props<WarrantyKPIParamsInterface>()
);

export const loadWarrantyKPIDataSuccess = createAction(
  '[Energy Analytics/API] Load warranty tracker kpi data Success',
  props<{ warrantyKPIData: WarrantyKPIDataInterface }>()
);

export const loadWarrantyKPIDataFailure = createAction(
  '[Energy Analytics/API] Load warranty tracker kpi data Failure',
  props<{ kpi: WarrantyKPIEnum; error: string }>()
);

export const cancelWarrantyKPIDataRequest = createAction('[Energy Analytics/API] Cancel warranty tracker kpi data http request');

export const fetchWarrantyKPIConfiguration = createAction('[Energy Analytics] Fetch warranty tracker kpi Configuration');

export const createWarrantyKPIConfiguration = createAction(
  '[Energy Analytics] Create warranty tracker kpi data',
  props<WarrantyKPIConfigurationParams>()
);

export const loadWarrantyKPIConfigurationSuccess = createAction(
  '[Energy Analytics/API] Load warranty tracker kpi Configuration Success',
  props<{ warrantyKPIConfiguration: WarrantyKPIConfiguration }>()
);

export const loadWarrantyKPIConfigurationFailure = createAction(
  '[Energy Analytics/API] Load warranty tracker kpi Configuration Failure',
  props<{ error: string }>()
);

export const cancelWarrantyKPIConfigurationRequest = createAction(
  '[Energy Analytics/API] Cancel warranty tracker kpi Configuration http request'
);

export const fetchWarrantyValidKPIConfiguration = createAction(
  '[Energy Analytics] Fetch warranty tracker valid kpi Configuration'
);

export const loadWarrantyValidKPIConfigurationSuccess = createAction(
  '[Energy Analytics/API] Load warranty tracker valid kpi Configuration Success',
  props<{ warrantyValidKPIConfiguration: ValidKPIConfiguration[] }>()
);

export const loadWarrantyValidKPIConfigurationFailure = createAction(
  '[Energy Analytics/API] Load warranty tracker valid kpi Configuration Failure',
  props<{ error: string }>()
);
// endregion

// region Health KPIs
export const fetchLatestHealthKPIData = createAction(
  '[Energy Analytics] Fetch latest health kpi data',
  props<{ kpi: HealthKPIEnum; params: HealthKPILatestParameters }>()
);

export const fetchAggregatedHealthKPIData = createAction(
  '[Energy Analytics] Fetch aggregated health kpi data',
  props<{ kpi: HealthKPIEnum; params: HealthKPIAggregatedParameters }>()
);

export const loadLatestHealthKPIDataSuccess = createAction(
  '[Energy Analytics/API] Load latest health kpi data Success',
  props<HealthKPILatestResponseSchema>()
);

export const loadAggregatedHealthKPIDataSuccess = createAction(
  '[Energy Analytics/API] Load aggregated health kpi data Success',
  props<HealthKPIAggregatedResponseSchema>()
);

export const loadLatestHealthKPIDataFailure = createAction(
  '[Energy Analytics/API] Load latest health kpi data failure',
  props<{ kpi: HealthKPIEnum; error: string }>()
);

export const loadAggregatedHealthKPIDataFailure = createAction(
  '[Energy Analytics/API] Load aggregated health kpi data failure',
  props<{ kpi: HealthKPIEnum; error: string }>()
);

export const cancelHealthKPIDataRequest = createAction('[Energy Analytics/API] Cancel health kpi data http request');
// endregion
