<div class="flex items-center gap-16">
  <nz-range-picker
    *ngIf="isDataExplorerSolution"
    twClampDateRange
    nzFormat="MMM d, y HH:mm"
    [(ngModel)]="pickerValue"
    (ngModelChange)="onDatePickerChange($event)"
    [nzRenderExtraFooter]="footerRender"
    [nzMode]="selectedTimeResolution?.value ?? 'date'"
    [nzShowTime]="{ nzFormat: 'HH:mm' }"
  ></nz-range-picker>
  <nz-range-picker
    *ngIf="!isDataExplorerSolution"
    twClampDateRange
    nzFormat="MMM d, y"
    [(ngModel)]="pickerValue"
    (ngModelChange)="onDatePickerChange($event)"
    [nzRenderExtraFooter]="footerRender"
    [nzMode]="selectedTimeResolution?.value ?? 'date'"
  ></nz-range-picker>
  <div class="flex items-center gap-4" *ngIf="showGranularity">
    <span class="font-normal text-sm leading-base text-tertiary-text">Granularity</span>
    <nz-select class="w-[100px]" [(ngModel)]="selectedGranularity" (ngModelChange)="onGranularityChange($event)">
      <nz-option *ngFor="let granularity of granularityOptions" [nzValue]="granularity" [nzLabel]="granularity | titlecase">
      </nz-option>
    </nz-select>
  </div>
  <div class="flex items-center gap-4" *ngIf="isDataExplorerSolution">
    <div
      class="flex font-normal text-sm leading-base text-tertiary-text"
      nz-tooltip
      nzTooltipPlacement="bottom"
      nzTooltipTitle="The actual time resolution may be lower than the selected value, depending on data availability"
    >
      <span>Resolution</span>
      <twaice-fe-svg-icon name="info-circle" source="tabler" size="13px" />
    </div>
    <nz-select
      class="w-[100px]"
      [compareWith]="compareTimeResolutionDEFn"
      [(ngModel)]="selectedTimeResolutionDE"
      (ngModelChange)="onTimeResolutionDEChange($event)"
    >
      <nz-option *ngFor="let granularity of timeResolutionDE" [nzValue]="granularity" [nzLabel]="granularity.label"> </nz-option>
    </nz-select>
  </div>
  <ng-template #footerRender>
    <div class="flex flex-col gap-16 px-[4px] py-16">
      <span class="text-base font-semibold" *ngIf="!isDataExplorerSolution">View resolution</span>
      <nz-segmented
        [nzBlock]="true"
        [nzOptions]="timeResolutionMapping"
        [(ngModel)]="selectedTimeResolutionIndex"
        (ngModelChange)="onTimeResolutionChange($event)"
        *ngIf="!isDataExplorerSolution"
      ></nz-segmented>
      <span class="text-base font-semibold">Time range</span>
      <nz-segmented
        [nzOptions]="viewTimeRanges"
        [ngModel]="selectedTimeRangeIndex"
        (ngModelChange)="onTimeRangeChange($event)"
        [nzBlock]="false"
      ></nz-segmented>
    </div>
  </ng-template>
</div>
