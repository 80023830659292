import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { selectors } from '@twaice-fe/frontend/shared/store';
import { SystemDetails, StorageLevel, StorageNameMapping } from '@twaice-fe/shared/models';

const { configsSelectors, systemSelectors } = selectors;

@Injectable({
  providedIn: 'root',
})
export class CustomerNameTranslationService implements OnDestroy {
  private useCustomerNaming = true;
  private systemMappings: { [key: string]: StorageNameMapping } = {};
  private selectedSystem: SystemDetails;

  private destroy$ = new Subject<void>();

  constructor(private store: Store) {
    combineLatest([
      this.store.select(configsSelectors.getUseCustomerNaming),
      this.store.select(configsSelectors.getStorageNameMappings),
      this.store.select(systemSelectors.getSelected),
    ])
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged((previous, current) => {
          const mappingHasSameKeys = Object.keys(previous[1]).sort() === Object.keys(current[1]).sort();
          const systemIsIdentical =
            previous[2] &&
            current[2] &&
            previous[2].customerBk === current[2].customerBk &&
            previous[2].systemBk === current[2].systemBk;
          return previous[0] === current[0] && mappingHasSameKeys && systemIsIdentical;
        })
      )
      .subscribe(([useCustomerNaming, systemMappings, selectedSystem]) => {
        this.useCustomerNaming = useCustomerNaming;
        this.systemMappings = systemMappings;
        this.selectedSystem = selectedSystem;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  translateComponentBk<T extends string | undefined>(componentBk: T): T extends undefined ? undefined : string {
    if (componentBk === undefined) {
      return undefined as any;
    }

    if (!this.useCustomerNaming || !this.systemMappings || !this.selectedSystem) {
      return componentBk as any;
    }

    const nameMappings = this.systemMappings[`${this.selectedSystem.customerBk}/${this.selectedSystem.systemBk}`];
    if (!nameMappings) return componentBk as any;

    return nameMappings.componentNameMapping[componentBk] || (componentBk as any);
  }

  replaceLevelIdentifiers<T extends string | undefined>(
    value: T,
    selectedStorageLevels: StorageLevel[] | undefined = undefined
  ): T extends undefined ? undefined : string {
    if (!value) return value as any;

    if (!selectedStorageLevels) {
      selectedStorageLevels = Object.values(StorageLevel);
    }

    const containsLevelName = selectedStorageLevels.some((level) => value.toLowerCase().includes(level.toLowerCase()));
    if (!containsLevelName) return value as any;

    return this.replaceLevelNames(
      value,
      this.useCustomerNaming,
      this.systemMappings,
      selectedStorageLevels,
      this.selectedSystem
    ) as any;
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */

  private replaceLevelNames(
    value: string,
    useCustomerNaming: boolean,
    systemMappings: { [key: string]: StorageNameMapping },
    storageLevels: StorageLevel[],
    selectedSystem?: SystemDetails
  ) {
    storageLevels.forEach((level) => {
      value = this.replaceLevelName(value, level, useCustomerNaming, systemMappings, selectedSystem);
    });
    return value;
  }

  private replaceLevelName(
    value: string,
    level: StorageLevel,
    useCustomerNaming: boolean,
    systemMappings: { [key: string]: StorageNameMapping },
    selectedSystem?: SystemDetails
  ): string {
    if (!value.toLowerCase().includes(level.toLowerCase())) return value;

    if (!systemMappings || !selectedSystem || typeof value !== 'string') return value;

    const systemMapping = systemMappings[`${selectedSystem.customerBk}/${selectedSystem.systemBk}`];
    if (!systemMapping) return value;

    const levelMapping = systemMapping.levelNameMapping[level];
    if (!levelMapping) return value;

    let name: string, namePlural: string;
    if (useCustomerNaming) {
      name = levelMapping.name;
      namePlural = levelMapping.namePlural;
    } else {
      name = levelMapping.defaultName;
      namePlural = levelMapping.defaultNamePlural;
    }

    value = this.casePreservingReplace(value, new RegExp(String.raw`\b${level}(?=\b)`, 'gi'), name);
    value = this.casePreservingReplace(value, new RegExp(String.raw`\b${level}s(?=\b)`, 'gi'), namePlural);

    return value;
  }

  private casePreservingReplace(str: string, regExp: RegExp, replacement: string) {
    return str.replace(regExp, (match) => {
      if (this.isFirstCharUpperCase(match)) {
        return this.capitalize(replacement);
      } else {
        return replacement;
      }
    });
  }

  private isFirstCharUpperCase(str: string) {
    return /^[A-Z]/.test(str);
  }

  private capitalize(str: string): string {
    return str.replace(/\w\S*/g, (text) => text.charAt(0).toUpperCase() + text.substring(1));
  }
}
