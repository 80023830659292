{
  "name": "tw_fe_monorepo",
  "version": "2.216.3",
  "description": "Twaice Monorepo",
  "author": "Twaice",
  "license": "MIT",
  "private": true,
  "engines": {
    "npm": ">=11",
    "node": ">=22.0.0"
  },
  "scripts": {
    "build": "nx build",
    "format": "npm run nx -- format:write",
    "lint": "nx lint",
    "check": "npm run format && npm run lint",
    "start": "npm run nx -- run-many --target=serve --projects=be4fe,twaice-ui --configuration=\"prod\" --parallel",
    "start:stg": "npm run nx -- run-many --target=serve --projects=be4fe,twaice-ui --configuration=\"stg\" --parallel",
    "start:dev": "npm run nx -- run-many --target=serve --projects=be4fe,twaice-ui --configuration=\"dev\" --parallel",
    "start:local-prod": "npm run nx -- run-many --target=serve --projects=be4fe,twaice-ui --configuration=\"local-prod\" --parallel",
    "start:local-stg": "npm run nx -- run-many --target=serve --projects=be4fe,twaice-ui --configuration=\"local-stg\" --parallel",
    "start:local-dev": "npm run nx -- run-many --target=serve --projects=be4fe,twaice-ui --configuration=\"local-dev\" --parallel",
    "start:e2e": "npm run nx -- run-many --target=serve --projects=be4fe,twaice-ui --configuration=\"local-prod\" --parallel",
    "test": "node --max_old_space_size=4096 --expose-gc ./node_modules/nx/bin/nx test --runInBand --logHeapUsage --silent ",
    "test:watch": "node --max_old_space_size=4096 --expose-gc ./node_modules/nx/bin/nx test --watch",
    "test:cov": "node --max_old_space_size=4096 --expose-gc ./node_modules/nx/bin/nx test --code-coverage --runInBand --logHeapUsage --silent",
    "test:debug": "node --inspect-brk -r tsconfig-paths/register -r ts-node/register node_modules/.bin/jest --runInBand",
    "e2e:twaice-ui": "nx e2e twaice-ui-e2e --ui",
    "e2e:twaice-ui:ci": "nx e2e twaice-ui-e2e",
    "generate-base-variables": "node ./generate-base-variables.js",
    "generate-ant-theme": "node ./tools/ant-theme/generate-theme.js",
    "generate-fleet-widget": "npm run nx -- run widgets-fleet-prediction:build:production --single-bundle && node apps/widgets/fleet-prediction/utilities/bundle-widget-script.js",
    "generate-fleet-widget:prod": "npm run nx -- run widgets-fleet-prediction:build:production --single-bundle && node apps/widgets/fleet-prediction/utilities/bundle-widget-script.js",
    "generate-fleet-widget:stg": "npm run nx -- run widgets-fleet-prediction:build:stg-deploy --single-bundle && node apps/widgets/fleet-prediction/utilities/bundle-widget-script.js",
    "generate-fleet-widget:dev": "npm run nx -- run widgets-fleet-prediction:build:dev-deploy --single-bundle && node apps/widgets/fleet-prediction/utilities/bundle-widget-script.js",
    "ng": "nx",
    "postinstall": "node ./decorate-angular-cli.js && npm run generate-base-variables",
    "nx": "NG_BUILD_DEBUG_PERF=1 node --max_old_space_size=4096 ./node_modules/nx/bin/nx",
    "affected:apps": "npm run nx -- affected:apps",
    "affected:libs": "npm run nx -- affected:libs",
    "affected:build": "npm run nx -- affected:build",
    "affected:test": "npm run nx -- affected:test",
    "affected:lint": "npm run nx -- affected:lint",
    "affected:dep-graph": "npm run nx -- affected:dep-graph",
    "affected": "npm run nx -- affected",
    "format:write": "npm run nx -- format:write",
    "format:check": "npm run nx -- format:check",
    "update": "npm run nx -- migrate latest",
    "dep-graph": "npm run nx -- dep-graph",
    "help": "npm run nx -- help",
    "workspace-generator": "npm run nx -- workspace-generator"
  },
  "dependencies": {
    "@angular/animations": "19.1.7",
    "@angular/cdk": "19.1.5",
    "@angular/common": "19.1.7",
    "@angular/compiler": "19.1.7",
    "@angular/core": "19.1.7",
    "@angular/elements": "19.1.7",
    "@angular/forms": "19.1.7",
    "@angular/localize": "19.1.7",
    "@angular/material": "16.2.12",
    "@angular/material-moment-adapter": "16.2.12",
    "@angular/platform-browser": "19.1.7",
    "@angular/platform-browser-dynamic": "19.1.7",
    "@angular/router": "19.1.7",
    "@aws-sdk/client-dynamodb": "3.421.0",
    "@aws-sdk/client-kms": "3.421.0",
    "@aws-sdk/client-s3": "3.421.0",
    "@aws-sdk/client-ssm": "3.421.0",
    "@aws-sdk/lib-dynamodb": "3.421.0",
    "@aws-sdk/s3-request-presigner": "3.421.0",
    "@databricks/sql": "1.8.1",
    "@nestjs/axios": "3.0.0",
    "@nestjs/common": "10.2.6",
    "@nestjs/core": "10.2.6",
    "@nestjs/jwt": "10.1.1",
    "@nestjs/passport": "10.0.2",
    "@nestjs/platform-express": "10.2.6",
    "@ngrx/effects": "19.0.1",
    "@ngrx/entity": "19.0.1",
    "@ngrx/operators": "^18.0.0",
    "@ngrx/router-store": "19.0.1",
    "@ngrx/store": "19.0.1",
    "@sentry/angular": "^9.0.0",
    "@sentry/browser": "^9.0.0",
    "@sentry/nestjs": "^9.0.0",
    "@sentry/profiling-node": "^9.0.0",
    "@storybook/addon-interactions": "8.6.0",
    "@stylistic/eslint-plugin-js": "^4.2.0",
    "@transifex/angular": "6.0.0",
    "@transifex/native": "6.0.0",
    "angular-tabler-icons": "3.26.0",
    "angularx-qrcode": "19.0.0",
    "aws-amplify": "5.3.11",
    "axios": "1.8.1",
    "class-transformer": "0.5.1",
    "class-validator": "0.14.0",
    "cookieconsent": "3.1.1",
    "d3": "7.8.5",
    "daterangepicker": "3.1.0",
    "dotenv": "16.3.1",
    "echarts": "^5.5.0",
    "file-saver": "2.0.5",
    "html2canvas": "1.4.1",
    "jquery": "3.7.1",
    "jszip": "3.10.1",
    "jwks-rsa": "3.0.1",
    "jwt-decode": "3.1.2",
    "mixpanel-browser": "^2.49.0",
    "moment": "2.29.4",
    "natural-compare": "^1.4.0",
    "nest-winston": "1.9.4",
    "ng-zorro-antd": "19.1.0",
    "ngx-feature-toggle": "12.0.0",
    "ngx-infinite-scroll": "19.0.0",
    "p-map": "4.0.0",
    "passport": "0.6.0",
    "passport-jwt": "4.0.1",
    "plotly.js-basic-dist": "2.26.1",
    "reflect-metadata": "0.1.13",
    "rrule": "^2.8.1",
    "rxjs": "7.8.1",
    "safe-pipe": "2.0.5",
    "scroll-into-view": "^1.16.2",
    "tslib": "^2.3.0",
    "winston": "3.10.0",
    "zone.js": "0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "19.1.8",
    "@angular-devkit/core": "19.1.8",
    "@angular-devkit/schematics": "19.1.8",
    "@angular-eslint/eslint-plugin": "19.1.0",
    "@angular-eslint/eslint-plugin-template": "19.1.0",
    "@angular-eslint/template-parser": "19.1.0",
    "@angular/cli": "~19.1.0",
    "@angular/compiler-cli": "19.1.7",
    "@angular/language-service": "19.1.7",
    "@cypress/code-coverage": "3.12.1",
    "@eslint/compat": "^1.2.7",
    "@eslint/eslintrc": "^3.3.1",
    "@eslint/js": "^9.23.0",
    "@istanbuljs/nyc-config-typescript": "1.0.2",
    "@mdx-js/react": "2.3.0",
    "@nestjs/cli": "10.1.18",
    "@nestjs/schematics": "10.0.2",
    "@nestjs/testing": "10.2.6",
    "@ngrx/schematics": "19.0.1",
    "@ngrx/store-devtools": "19.0.1",
    "@nx/angular": "20.4.6",
    "@nx/cypress": "20.4.6",
    "@nx/eslint": "20.6.4",
    "@nx/eslint-plugin": "20.6.4",
    "@nx/jest": "20.4.6",
    "@nx/js": "20.4.6",
    "@nx/module-federation": "20.4.6",
    "@nx/nest": "20.4.6",
    "@nx/node": "20.4.6",
    "@nx/playwright": "20.4.6",
    "@nx/storybook": "20.4.6",
    "@nx/workspace": "20.4.6",
    "@playwright/test": "^1.40.0",
    "@schematics/angular": "19.1.8",
    "@sentry/webpack-plugin": "^2.16.0",
    "@storybook/addon-actions": "8.6.0",
    "@storybook/addon-designs": "7.0.5",
    "@storybook/addon-essentials": "8.6.0",
    "@storybook/addon-mdx-gfm": "8.6.0",
    "@storybook/angular": "8.6.0",
    "@swc-node/register": "1.9.2",
    "@swc/core": "1.5.7",
    "@testing-library/angular": "17.3.6",
    "@testing-library/jest-dom": "6.6.3",
    "@transifex/cli": "6.0.1",
    "@types/cache-manager": "4.0.3",
    "@types/cookieconsent": "3.1.1",
    "@types/d3": "7.4.1",
    "@types/daterangepicker": "3.1.6",
    "@types/express": "4.17.21",
    "@types/file-saver": "2.0.5",
    "@types/jest": "29.5.14",
    "@types/jquery": "3.5.20",
    "@types/mdx": "2.0.8",
    "@types/mixpanel-browser": "^2.49.0",
    "@types/natural-compare": "^1.4.3",
    "@types/node": "18.16.9",
    "@types/passport-jwt": "3.0.10",
    "@types/plotly.js": "2.12.27",
    "@types/scroll-into-view": "^1.16.4",
    "@types/supertest": "2.0.13",
    "@typescript-eslint/eslint-plugin": "8.28.0",
    "@typescript-eslint/parser": "8.28.0",
    "@typescript-eslint/utils": "^8.28.0",
    "@vercel/ncc": "0.34.0",
    "autoprefixer": "10.4.16",
    "concat": "1.0.3",
    "cypress": "13.17.0",
    "cypress-file-upload": "5.0.8",
    "eslint": "9.23.0",
    "eslint-config-prettier": "9.1.0",
    "eslint-plugin-cypress": "4.2.0",
    "eslint-plugin-import": "2.31.0",
    "eslint-plugin-storybook": "0.12.0",
    "fs-extra": "11.1.1",
    "glob": "10.3.10",
    "jest": "29.7.0",
    "jest-environment-jsdom": "29.7.0",
    "jest-environment-node": "29.7.0",
    "jest-preset-angular": "14.5.3",
    "jsonc-eslint-parser": "^2.4.0",
    "ngx-build-plus": "19.0.0",
    "nx": "20.4.6",
    "nyc": "15.1.0",
    "postcss": "8.4.30",
    "prettier": "3.0.3",
    "react": "18.2.0",
    "react-dom": "18.2.0",
    "source-map-support": "0.5.21",
    "ssri": "10.0.5",
    "storybook": "8.6.0",
    "supertest": "6.3.3",
    "tailwindcss": "3.3.3",
    "ts-jest": "29.1.1",
    "ts-loader": "9.4.4",
    "ts-node": "10.9.1",
    "tsconfig-paths": "4.2.0",
    "typescript": "5.7.3",
    "webpack": "5.88.2"
  },
  "nyc": {
    "extends": "@istanbuljs/nyc-config-typescript",
    "all": true
  },
  "overrides": {
    "@mdx-js/react": {
      "react": "18.2.0"
    },
    "@transifex/angular": {
      "@angular/core": "$@angular/core",
      "@angular/common": "$@angular/common",
      "@angular/cdk": "$@angular/cdk"
    },
    "@angular/material": {
      "@angular/animations": "$@angular/animations",
      "@angular/cdk": "$@angular/cdk",
      "@angular/common": "$@angular/common",
      "@angular/core": "$@angular/core",
      "@angular/forms": "$@angular/forms",
      "@angular/platform-browser": "$@angular/platform-browser",
      "rxjs": "$rxjs"
    },
    "@angular/material-moment-adapter": {
      "@angular/animations": "$@angular/animations",
      "@angular/cdk": "$@angular/cdk",
      "@angular/common": "$@angular/common",
      "@angular/forms": "$@angular/forms",
      "@angular/platform-browser": "$@angular/platform-browser",
      "@angular/core": "$@angular/core",
      "rxjs": "$rxjs"
    }
  }
}
