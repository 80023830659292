import { createAction, props } from '@ngrx/store';
import { SystemDetails, TimeRangeGranularityEnum, TimeRangeInterface, TimeResolution } from '@twaice-fe/shared/models';
import { TimeRangeEnum } from '@twaice-fe/shared/utilities';

export const fetchSystemDetails = createAction('[Energy Analytics] Fetch system details data');

export const loadSystemDetailsSuccess = createAction(
  '[Energy Analytics/API] Load system details Success',
  props<{ systemDetails: SystemDetails[] }>()
);

export const loadSystemDetailsFailure = createAction(
  '[Energy Analytics/API] Load system details Failure',
  props<{ error: string }>()
);

export const cancelSystemDetailsRequest = createAction('[Energy Analytics/API] Cancel system details http request');

export const selectSystem = createAction('[Systems] Select system by id', props<{ systemId: string }>());

export const systemRouteSettled = createAction('[Systems] System Route Settled', props<{ systemId?: string }>());

export const setInitialTimeRange = createAction(
  '[Systems/API] Set initial and available time ranges',
  props<{ timeRange: TimeRangeInterface; availableTimeRanges: TimeRangeInterface[] }>()
);

export const setTimeRange = createAction('[Systems/API] Set time range', props<{ timeRange: TimeRangeInterface }>());

export const setTimeRangeGranularity = createAction(
  '[Systems/API] Set time range granularity',
  props<{ granularity: TimeRangeGranularityEnum }>()
);

export const setTimeResolution = createAction(
  '[Systems/API] Set time range granularity for DE',
  props<{ granularity: TimeResolution }>()
);

export const setDefaultTimeRange = createAction(
  '[Systems/API] Set default time range',
  props<{ defaultTimeRange: TimeRangeEnum }>()
);

export const timeRangeUrlSyncSuccess = createAction('[Systems/API] Time Range URL sync success');
